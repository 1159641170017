<template>
  <div class="auto-rules">
    <el-button
      type="warning"
      size="medium"
      :disabled="loading"
      :loading="loading"
      @click="getRules"
      id="autoRulesPc"
    >
      {{ btnText }}
    </el-button>
    <el-dialog title="自动化规则" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="form" label-width="130px">
        <el-form-item label="广告账号ID">
          <el-select v-model="scopeValue" multiple placeholder="请选择广告账号">
            <el-option
              v-for="item in hasActiveAdAccountsLists"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自动化规则方案">
          <el-select v-model="form.ruleId" placeholder="请选择自动化规则方案">
            <el-option
              v-for="item in rulesList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="automation"> 立即执行 </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getRules, automation } from "@/api/autoRules.js";
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: { scopeValue: "" },
      rulesList: [],
      btnText: "自动化规则",
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.userInfo
    }),
    ...mapGetters(["token", "accountId"]),
    ...mapGetters("num", ["hasActiveAdAccountsLists"]),
    scopeValue: {
      set(val) {
        if (val) {
          this.$set(this.form, "scopeValue", val.join());
        }
      },
      get() {
        return this.form.scopeValue ? this.form.scopeValue.split(",") : [];
      },
    },
  },
  watch: {
    accountId(val) {
      if (val) {
        this.form.scopeValue = val;
      }
    },
  },
  methods: {
    getRules() {
      if (!this.rulesList.length) {
        this.loading = true;
        getRules({ _token: this.token })
          .then((res) => {
            console.log(res);
            this.loading = false;
            if (res.data && res.data.length) {
              this.rulesList = res.data;
              this.dialogVisible = true;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else {
        this.dialogVisible = true;
      }
    },
    automation() {
      const { form } = this;
      if (!form.ruleId && form.ruleId != 0) {
        this.$message.error("请选择自动化规则方案");
        return;
      }
      if (!form.scopeValue) {
        this.$message.error("请选择广告账号");
        return;
      }
      const userId = this.user.data.id;
      let _token = this.token;
      // let _token = "b10e1f42dc0f511750d6197c88843323";
      this.loading = true;
      this.btnText = "自动化规则执行中";
      this.dialogVisible = false;
      this.$notify.info({
        title: "自动化规则开始执行",
        message: "这可能需要一些时间，你可以继续其它工作，但请不要刷新浏览器。",
      });
      automation({ ...form, _token, userId })
        .then((res) => {
          //   console.log(res);
          if (!res.data) {
            this.$notify({
              title: "自动化规则执行成功",
              message: res.message,
              type: "success",
            });
          } else {
            this.$notify({
              title: "成功",
              message: "自动化规则执行成功",
              type: "success",
            });
          }
          this.loading = false;
          this.btnText = "自动化规则完成";
        })
        .catch((err) => {
          this.$notify({
            title: "自动化规则执行失败",
            message: err.message,
            type: "error",
          });
          this.loading = false;
          this.btnText = "自动化规则执行失败";
          console.log(err);
        });
    },
  },
  mounted() {
    if (
      this.accountId &&
      (!this.form.scopeValue || !this.form.scopeValue.includes(this.accountId))
    ) {
      this.form.scopeValue = this.accountId;
    }
  },
};
</script>
<style lang="scss" scoped>
.auto-rules {
  padding-left: 15px;
  .el-select {
    display: block;
  }
}
</style>