<template>
  <div>
    <el-dropdown
      ref="accountDropDown"
      trigger="click"
      placement="bottom-start"
      style="margin-left: 30px"
      @visible-change="showDropdown"
      :key="renderKey"
    >
      <div class="account-wrapper">
        <div
          class="pr mx-10"
          :class="['unf', activePlat.name != 'f']"
        >
          <i
            v-if="activePlat.name != 'f'"
            class="el-icon-info"
          ></i>
          <span
            v-else
            class="define-f dp-flex justify-c align-item-c"
            >{{ activePlat.name }}</span
          >
          <p
            :class="[
              'f-circle',
              'pa',
              activeAdAccount && activeAdAccount.accountStatus == 1 ? 'bg-success' : 'bg-danger',
            ]"
          ></p>
        </div>
        <el-tooltip
          :content="activeAdAccount.displayName || activeAdAccount.name"
          placement="right"
          effect="light"
        >
          <p
            class="ellipsis-1"
            style="line-height: initial"
          >
            {{ activeAdAccount.displayName || activeAdAccount.name }}
            <!-- giikin-{{activeAdAccount.id}} -->
          </p>
        </el-tooltip>
        <i class="el-icon-caret-bottom"></i>
      </div>
      <el-dropdown-menu slot="dropdown">
        <div class="account-list-container pr">
          <!-- 刷新-搜索-来源 -->
          <cus-header
            @search="handleSearch"
            ref="cusHeader"
          ></cus-header>
          <ul class="account-list-wrapper">
            <li
              class="account-list-item"
              :class="item.active ? 'active' : ''"
              v-for="item in !isSearch ? hasActiveAdAccountsLists : searchResList"
              :key="item.id"
              @click="chooseAccount(item)"
            >
              <div class="account-list-item-top">
                <div class="icon-wrapper pr">
                  <i
                    v-if="activePlat.name != 'f'"
                    class="el-icon-info"
                  ></i>
                  <img
                    v-else
                    style="width: 15px; height: 15px"
                    src="https://app.sinoclick.com/static/media/fb.d03076a0.svg"
                  />
                  <p :class="['small-circle', 'pa', item.accountStatus == 1 ? 'bg-success' : 'bg-danger']"></p>
                </div>
                <!-- {{ item.displayName +'('+item.name+')' }} -->
                <!-- <el-tooltip :content="`giikin-${item.id}`"><span class="account-name">giikin-{{ item.id }}</span></el-tooltip> -->
                <el-tooltip
                  :content="item.displayName + '(' + item.name + ')'"
                  :disabled="getTextSize(item.displayName + '(' + item.name + ')', 14).width < 290"
                  ><span class="account-name"
                    >{{ item.displayName || item.name }}<span v-if="item.displayName">({{ item.name }})</span></span
                  ></el-tooltip
                >
                <span
                  class="current"
                  v-if="item.active"
                  >当前</span
                >
                <span class="current">近7天花费:{{ item.originSpend }}</span>
              </div>
              <div class="account-list-item-bottom">
                <span class="account-id">ID:{{ item.id }}</span>
                <el-tooltip :content="`当前账号在投放中/审核中广告数量：${item.adsRunningCount}`">
                  <span class="status-disabled num bg-success">{{ item.adsRunningCount }}</span>
                </el-tooltip>
                <el-tooltip content="禁用">
                  <span
                    v-if="item.accountStatus != 1"
                    class="status-disabled"
                    >禁用</span
                  >
                </el-tooltip>
                <el-tooltip
                  :content="`可用额度${item.currency.key}${
                    (item.spendCap - item.amountSpent) / item.currency.uiUnit.multiToBase
                  }`"
                >
                  <span
                    :class="[
                      'status-disabled',
                      item.accountStatus == 1
                        ? (item.spendCap - item.amountSpent) / item.currency.uiUnit.multiToBase > 2000
                          ? 'green'
                          : 'orange'
                        : '',
                    ]"
                    >{{ (item.spendCap - item.amountSpent) / item.currency.uiUnit.multiToBase }}</span
                  >
                </el-tooltip>
                <el-tooltip content="额度低">
                  <span
                    v-if="item.spendCap && (item.spendCap - item.amountSpent) / 100 < 5"
                    class="status-disabled"
                    >额度低</span
                  >
                </el-tooltip>
                <!-- <el-tooltip content="账户余额不足2000">
                  <span
                    v-if="
                      item.spendCap &&
                      (item.spendCap - item.amountSpent) / 100 >= 5 &&
                      (item.spendCap - item.amountSpent) / 100 <= 2000
                    "
                    class="status-disabled"
                    >余额不足2000</span
                  >
                </el-tooltip> -->
                <span
                  class="current"
                  @click.stop="newWindowOpenAccount(item)"
                  >新窗口打开</span
                >
                <span
                  class="current"
                  @click.stop="recharge(item)"
                  v-if="item.accountStatus == 1"
                  >充值</span
                >
              </div>
            </li>
          </ul>
          <div
            v-if="noSearchData"
            class="pa dp-flex justify-c align-item-c"
            style="top: 70px; bottom: 0; left: 0; right: 0"
          >
            无匹配数据
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <recharge
      :dialogVisible="showRecharge"
      @close="closeRecharge"
      :account_id="rechargeId"
    ></recharge>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import cusHeader from './cusHeader';
import { checkAccountStatus } from '@/api/common/account';
import { adaccouts } from '@/api/common/user';
import merge from 'webpack-merge';
import recharge from '@/components/google/recharge';
import { getTextSize } from '@/utils/utils';
export default {
  components: {
    cusHeader,
    recharge,
  },
  data() {
    return {
      // 当前选中的来源平台-便于后期平台切换时帐号前图标的转换
      activePlat: '',
      // 本地搜索
      isSearch: false,
      searchResList: [],
      noSearchData: false,
      showDropdownVisiable: true,
      renderKey: 0,
      showRecharge: false, //充值弹框
      rechargeId: '',
    };
  },
  computed: {
    ...mapState('num', ['adAccountsLists', 'activeAdAccount', 'selectedNum']),
    ...mapGetters('num', ['hasActiveAdAccountsLists', 'defNum']),
    // activeCurrentAccount(){
    //     let currAccount = this.getQuery(this.$router.currentRoute.fullPath).account_id;
    //     console.log(currAccount);
    //     if(currAccount){
    //       return this.adAccountsLists.find(el => el.id == currAccount)
    //     }else{
    //       return this.activeAdAccount
    //     }
    // }
  },
  methods: {
    getTextSize(text, fontSize) {
      return getTextSize(text, fontSize);
    },
    getQuery(url) {
      if (!url.split('?')[1]) {
        return {};
      }
      let arr = url.split('?')[1].split('&');
      let obj = {};
      arr.map((v) => {
        obj[v.split('=')[0]] = v.split('=')[1];
      });
      return obj;
    },
    showDropdown(v) {
      console.log(v);
      this.showDropdownVisiable = v;
    },
    initActiveAdAccount(arr) {
      if (arr.length) {
        // 启用帐号中的第一个
        let eableAdAccounts = arr.filter((item) => item.accountStatus == 1);
        if (eableAdAccounts) {
          return {
            ...eableAdAccounts[0],
          };
        } else {
          // 禁用帐号中的第一个
          return {
            ...arr[0],
          };
        }
      }
    },
    sorceActiveAccount(obj) {
      let lists =
        this.$store.state.num.adAccountsLists && this.$store.state.num.adAccountsLists.length
          ? [...this.$store.state.num.adAccountsLists]
          : [];
      let hasLocalAccount = null;

      let currAccount = this.getQuery(this.$router.currentRoute.fullPath).account_id;
      let activeAdAccount = lists.length
        ? currAccount
          ? lists.find((el) => el.id == currAccount)
          : obj && lists.find((el) => el.id == obj.id)
          ? lists.find((el) => el.id == obj.id)
          : window.activeAdAccount && lists.find((el) => el.id == window.activeAdAccount.id)
          ? window.activeAdAccount
          : hasLocalAccount &&
            Object.keys(JSON.parse(hasLocalAccount)).length &&
            lists.find((el) => el.id == JSON.parse(hasLocalAccount).id)
          ? JSON.parse(hasLocalAccount)
          : this.initActiveAdAccount([...lists])
        : {};
      console.log(activeAdAccount);
      return activeAdAccount;
    },
    getAdAccounts({ obj, params }) {
      this.$showLoading();
      let _params = {
        keyword: '',
        limit: 10000,
        page: 1,
        ...params,
        id: this.selectedNum, //个号id
      };
      return adaccouts(_params).then(async (res) => {
        this.$hideLoading();

        if (res.code == 200) {
          // 广告帐号的所有信息数据以便其它页面调用（包括更新时间、花费等）
          await this.$store.dispatch('num/setAdAccountsAllDatas', {
            allDatas: res.data,
          });

          await this.$store.dispatch('num/setAdAccountsLists', {
            adAccountsLists: res.data.list && res.data.list.length ? [...res.data.list] : [],
          });
          console.log(this.adAccountsLists);
          if (res.data.list.length) {
            let timestamp = new Date().getTime(); // 防止路由重复导致页面不更新
            if (!obj) {
              this.$router.push({
                query: merge(this.$route.query, {
                  account_id: res.data.list[0].id,
                  third_user_id: this.selectedNum,
                  timestamp: timestamp,
                }),
              });
              this.renderKey += 1;
              // window.location.reload();
            } else {
              this.$router.push({
                query: merge(this.$route.query, {
                  account_id: obj.id,
                  third_user_id: this.selectedNum,
                  timestamp: timestamp,
                }),
              });
              this.renderKey += 1;
            }
            await this.$store.dispatch('num/setActiveAdAccount', {
              activeAdAccount:
                obj && Object.keys(this.sorceActiveAccount(obj)).length
                  ? this.sorceActiveAccount(obj)
                  : res.data.list[0]
                  ? res.data.list[0]
                  : {},
            });
          }

          if (!this.$store.state.num.adAccountsLists.length) {
            this.$router.push({
              name: 'AdAssets',
            });
            // 有头部导航
            await this.$store.dispatch('app/hideNavbarAccount', {
              hideNavbarAccount: false,
            });
            this.$confirm(
              '您当前个号下无广告帐户, 请同步帐户或前往配置页面重新授权相应个号，否则无法进行后续操作！',
              '同步帐户',
              {
                confirmButtonText: '同步帐户',
                cancelButtonText: '取消',
                type: 'warning',
              },
            )
              .then(() => {
                this.$bus.$emit('trigger-synBM');
              })
              .catch(() => {
                Message({
                  message: '已取消同步帐号！',
                  type: 'info',
                });
              });
          }
        }
      });
    },
    // 检查广告帐号状态-启用/禁用
    checkAccountStatus(item) {
      this.$showLoading();
      let tipHtml = `<p style="font-weight: bold;font-size: 14px;">广告账号禁用</p><p style="margin-top: 10px;">你选择的广告账号<strong>${item.name}</strong>状态是：<strong>禁用</strong> ，只有启用中的账号才能创建/编辑广告。确定切换到该账号吗？</p>`;
      return checkAccountStatus({
        id: item.id,
      }).then(async (res) => {
        this.$hideLoading();
        if (res.code == 0) {
          let pass = res.data.check_pass;
          // 禁用时弹出切换确认框
          if (!pass) {
            this.$confirm(tipHtml, '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              customClass: 'change-status-msg',
              type: 'warning',
              dangerouslyUseHTMLString: true,
            })
              .then(async () => {
                await this.getAdAccounts({
                  obj: item,
                });
                // this.$router.push({
                //   query:merge(this.$route.query,{account_id:item.id,third_user_id:this.selectedNum})
                // })
                this.$refs.accountDropDown.visible = false;
              })
              .catch(async () => {
                this.$message({
                  type: 'info',
                  message: `已取消切换到帐号${item.name}!`,
                });
                await this.getAdAccounts({
                  obj: this.activeAdAccount,
                });
              });
          } else {
            await this.getAdAccounts({
              obj: item,
            });
            // console.log(this.$router);
            // this.$router.push({
            //   query:merge(this.$route.query,{account_id:item.id,third_user_id:this.selectedNum})
            // })
            this.$refs.accountDropDown.visible = false;
          }
        }
      });
    },
    checkAccountStatus2(item, href) {
      this.$showLoading();
      let tipHtml = `<p style="font-weight: bold;font-size: 14px;">新窗口打开确认</p><p style="margin-top: 10px;">你选择的广告账号<strong>${item.name}</strong>状态是：<strong>禁用</strong> ，只有启用中的账号才能创建/编辑广告。确定在新窗口打开该账号吗？</p>`;
      return checkAccountStatus({
        id: item.id,
      }).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          let pass = res.data.check_pass;
          // 禁用时弹出切换确认框
          if (!pass) {
            this.$confirm(tipHtml, '', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              customClass: 'change-status-msg',
              type: 'warning',
              dangerouslyUseHTMLString: true,
            })
              .then(() => {
                window.open(href, '_blank');
                this.$refs.accountDropDown.visible = false;
              })
              .catch(async () => {
                this.$message({
                  type: 'info',
                  message: `已取消切换到帐号${item.name}!`,
                });
                await this.getAdAccounts({
                  obj: this.activeAdAccount,
                });
              });
          } else {
            window.open(href, '_blank');
            this.$refs.accountDropDown.visible = false;
          }
        }
      });
    },
    async chooseAccount(item) {
      await this.checkAccountStatus(item);
    },
    async newWindowOpenAccount(item) {
      let { href, route } = this.$router.resolve({
        path: this.$router.currentRoute.path,
        query: {
          account_id: item.id,
          third_user_id: this.selectedNum,
        },
      });
      await this.checkAccountStatus2(item, href);
    },
    // 本地搜索
    handleSearch(ipt) {
      this.isSearch = true;
      let arr = [];
      if (ipt) {
        arr = this.hasActiveAdAccountsLists.filter(
          (item) =>
            item.name.indexOf(ipt) != -1 ||
            item.id.indexOf(ipt) != -1 ||
            (item.displayName && item.displayName.indexOf(ipt) != -1),
        );
      } else {
        arr = [...this.hasActiveAdAccountsLists];
      }
      this.noSearchData = arr.length ? false : true;
      this.searchResList = arr;
    },
    // 充值
    recharge({ id }) {
      this.rechargeId = id;
      this.showRecharge = true;
    },
    // 关闭充值弹框
    closeRecharge() {
      this.rechargeId = '';
      this.showRecharge = false;
    },
  },
  created() {
    this.$bus.$on('trigger-getAdAccounts', (params) => {
      console.log('$on-trigger-getAdAccountsparams', params);
      this.getAdAccounts({
        ...params,
      });
    });
    this.$bus.$on('post-activePlat', (activePlat) => {
      this.activePlat = activePlat;
    });
  },
};
</script>

<style lang="scss" scoped>
.account-wrapper {
  display: flex;
  align-items: center;
  width: 360px;
  height: 28px;
  font-size: 14px;
  color: #fff;
  background: #0c5dc2;
  cursor: pointer;

  i {
    margin: 0 10px;
  }

  i.el-icon-caret-bottom {
    margin-left: auto;
  }
}

.account-list-container {
  padding: 0 16px 12px;

  ul.account-list-wrapper {
    width: 900px;
    height: 300px;
    min-height: 130px;
    overflow-y: auto;

    li.account-list-item {
      width: 50%;
      float: left;
      cursor: pointer;
      height: 55px;
      position: relative;
      transition: all 0.3s linear 0s;
      margin-left: 0 !important;
      padding-left: 0 !important;

      &:hover {
        background-color: rgb(245, 245, 247);
      }

      .account-list-item-top {
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;

        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          font-size: 20px;
          color: #247ae6;
        }

        .account-name {
          display: block;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          float: left;
          // max-width: 232px;
          font-size: 14px;
        }
      }

      .current {
        border-radius: 2px;
        border: 1px solid rgb(178, 208, 246);
        font-size: 10px;
        padding: 1px 8px;
        margin-left: 7px;
        color: rgb(36, 122, 230);
      }

      .account-list-item-bottom {
        display: flex;
        align-items: center;
        width: 100%;
        height: 20px;

        .account-id {
          font-size: 12px;
          color: rgb(185, 187, 190);
          opacity: 0.8;
          padding-left: 16px;
          margin-left: 20px;
        }

        .status-disabled {
          display: inline-block;
          margin-left: 0;
          padding: 1px 8px;
          font-size: 12px;
          color: #f56c6c;
          border: 1px solid #f56c6c;
          transform: scale(0.8);
          border-radius: 2px;
          margin: 0 5px;
          &.green {
            color: #67c23a;
            border-color: #67c23a;
          }
          &.orange {
            color: #e6a23c;
            border-color: #e6a23c;
          }
          &.num {
            border: none;
            background: #c0c4cc;
            color: #fff;
            transform: scale(1);
          }
        }
      }
    }

    li.active {
      background: rgb(238, 244, 255);
    }
  }
}

.f-circle,
.small-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.f-circle {
  top: -2px;
  right: -2px;
}

.small-circle {
  top: 8px;
  left: 20px;
}

.change-status-msg {
  .el-message-box__status {
    top: unset;
    transform: translateY(0);
  }
}

.define-f {
  width: 15px;
  height: 15px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.unf {
  line-height: initial;

  i {
    margin: 0;
    font-size: 15px;
  }
}
</style>
